function menu() {
  const menuBtn = document.querySelector('.js-menu-btn');

  if (!menuBtn) return;

  const menu = document.querySelector('.js-menu');

  menuBtn.addEventListener('click', menuHandlerInit)

  function showMenu() {
    scrollToggleLock();

    menu.classList.add('_active');
    menuBtn.classList.add('_active');

    menuBtn.removeEventListener('click', menuHandlerInit)
    document.addEventListener('click', menuHandler)
  }

  function closeMenu() {
    scrollToggleLock();

    menu.classList.remove('_active');
    menuBtn.classList.remove('_active');

    document.removeEventListener('click', menuHandler)
    menuBtn.addEventListener('click', menuHandlerInit)
  }

  function menuHandler(e) {
    const target = e.target;

    if (!target.closest('.menu__content') || target.closest('.js-menu-btn')) {
      closeMenu();
    }
  }

  function menuHandlerInit(e) {
    e.stopPropagation();

    showMenu();
  }
}