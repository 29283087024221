function searchShowToggle() {
  const searchBtns = document.querySelectorAll('.js-search-btn');

  if (!searchBtns[0]) return;

  let activeSearch = null;

  document.addEventListener('click', (e) => {
    const target = e.target;

    if (target.closest('.js-search-btn')) {
      const searchElememnt = target.closest('.js-search'),
      searchInput = searchElememnt.querySelector('.js-search-input');

      if (activeSearch && activeSearch !== searchElememnt) {
        hideSearch(activeSearch);
      }

      if (!activeSearch || !searchInput.value.length) {
        e.preventDefault();
      }

      if (activeSearch && !searchInput.value.length) {
        hideSearch(activeSearch);

        return;
      }

      showSearch(searchElememnt)

      return;
    }

    if (activeSearch && !target.closest('.js-search-form')) {
      hideSearch(activeSearch)
    }
  })

  function showSearch(element) {
    element.classList.add('_active');
    element.closest('.container').classList.add('search--active');

    activeSearch = element;
  }

  function hideSearch(element) {
    element.classList.remove('_active');
    element.closest('.container').classList.remove('search--active');

    activeSearch = null;
  }

  // function initSearchWidth() {
  //   searchBtns.forEach(btn => {
  //     const search = btn.closest('.js-search'),
  //     searchInput = search.querySelector('input'),
  //     list = search.closest('.social__list');

  //     searchInput.style.width = `${list.clientWidth}px`
  //   })
  // }

  // if (window.innerWidth <= 767) {
  //   initSearchWidth();
  // }
  // window.addEventListener('resize', initSearchWidth)
}