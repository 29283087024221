const showMoreAjax = () => {
    $(document).on('click', '.load_more', function (e) {
        var btnContainer = $(this).parent().parent();
        var section = btnContainer.data('section');
        var targetContainer = btnContainer.find('.js-showMore-container'),
            url = $(this).attr('data-url'),
            count = $(this).attr('data-count');

        if (url !== undefined) {
            url += `&section=${section}`;
            $.ajax({
                type: 'GET',
                url: url,
                dataType: 'html',
                success: function (data) {
                    btnContainer.find('.load_more').remove();
                    var elements = $(data).find(`.js-showMore-item_${count}`),
                        pagination = $(data).find(`.load_more_${count}`);
                    console.log(elements);

                    targetContainer.append(elements);
                    btnContainer.find('.loadMore').append(pagination);
                    new LazyLoad({});
                }
            })
        }
    });
}
