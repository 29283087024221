function sliders() {
  if (document.querySelector('.js-banner-slider')) {
    const banner = document.querySelector('.js-banner-slider');

    const bannerSlider = new Swiper(banner, {
      spaceBetween: 30,
      lazy: true,
      preloadImages: false,
      rewind: true,
      slidesPerView: 1,
      navigation: {
        nextEl: '.banner__arrow-next',
        prevEl: '.banner__arrow-prev',
      },
      pagination: {
        el: '.banner__pagination',
        type: 'bullets',
        clickable: true,
      },
      breakpoints: {
        768: {
          slidesPerView: 2,
        }
      }
    })
  }

  if (document.querySelector('.js-material-slider')) {
    const material = document.querySelector('.js-material-slider');

    const materialSlider = new Swiper(material, {
      spaceBetween: 16,
      lazy: true,
      preloadImages: false,
      rewind: true,
      slidesPerView: 1,
      navigation: {
        nextEl: '.banner__arrow-next',
        prevEl: '.banner__arrow-prev',
      },
      pagination: {
        el: '.banner__pagination',
        type: 'bullets',
        clickable: true,
      },
    })
  }

  if (document.querySelector('.js-podcast-slider')) {
    const podcast = document.querySelector('.js-podcast-slider'),
    podcastWrapper = podcast.closest('.js-podcast-slider-wrapper'),
    podcastPrev = podcastWrapper.querySelector('.js-podcast-arrow-prev'),
    podcastNext = podcastWrapper.querySelector('.js-podcast-arrow-next');

    const podcastSlider = new Swiper(podcast, {
      spaceBetween: 16,
      rewind: true,
      slidesPerView: 'auto',
      navigation: {
        prevEl: podcastPrev,
        nextEl: podcastNext,
      },
      breakpoints: {
        768: {
          spaceBetween: 30,
          slidesPerView: 2,
        },
        925: {
          spaceBetween: 30,
          slidesPerView: 3,
        },
        1150: {
          spaceBetween: 30,
          slidesPerView: 4,
        },
      }
    })

    if (podcastSlider.wrapperEl.scrollWidth <= podcastSlider.wrapperEl.clientWidth) {
      podcastPrev.remove()
      podcastNext.remove()
    }
  }

  if (document.querySelector('.js-materials-podcast-slider')) {
    const podcast = document.querySelector('.js-materials-podcast-slider'),
    podcastContent = document.querySelector('.js-materials-podcast-slider-content'),
    podcastWrapper = podcast.closest('.js-materials-podcast-slider-wrapper'),
    podcastPrev = podcastWrapper.querySelector('.js-podcast-arrow-prev'),
    podcastNext = podcastWrapper.querySelector('.js-podcast-arrow-next');

    const podcastSlider = new Swiper(podcast, {
      spaceBetween: 16,
      rewind: true,
      slidesPerView: 'auto',
      navigation: {
        prevEl: podcastPrev,
        nextEl: podcastNext,
      },
      breakpoints: {
        768: {
          spaceBetween: 9,
        },
        1441: {
          spaceBetween: 9,
          slidesPerView: 3,
        }
      }
    })

    const podcastSliderContent = new Swiper(podcastContent, {
      spaceBetween: 16,
      allowTouchMove: false,
      thumbs: {
        swiper: podcastSlider,
      },
      breakpoints: {
        768: {
          spaceBetween: 9,
        }
      }
    })

    console.log(podcastSlider.wrapperEl.scrollWidth, podcastSlider.wrapperEl.clientWidth)

    if (podcastSlider.wrapperEl.scrollWidth <= podcastSlider.wrapperEl.clientWidth) {
      podcastPrev.remove()
      podcastNext.remove()
    }
  }
}