function stopPropagation() {
  const triggerSelectors = ['.social__link']

  document.addEventListener('click', (e) => {
    const target = e.target;

    for (let i = 0; i < triggerSelectors.length; i++) {
      if (target.closest(triggerSelectors[i])) {
        e.stopPropagation();

        break;
      }
    }
  })
}