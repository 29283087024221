'use strict'

document.addEventListener('DOMContentLoaded', () => {
    let pageTopOffset = null;
    var scroll = new SmoothScroll('a[href*="#"]', {
        speed: 500,
        speedAsDuration: true
    });

    svg4everybody();

    menu();
    sliders();
    stopPropagation();
    searchShowToggle();
    showMoreAjax();
    //dropdown();

    new LazyLoad({});
})
