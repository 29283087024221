function scrollToggleLock() {
	const scrollWidth = window.innerWidth - document.body.offsetWidth,
				site = document.querySelector('.site-wrapper');

	if (site.classList.contains('scrollLock')) {
		site.removeAttribute('style');
    site.classList.remove('scrollLock');

		pageTopOffset && window.scrollTo(0, pageTopOffset);
	} else {
		pageTopOffset = window.pageYOffset;

    site.classList.add('scrollLock');

		site.style.cssText = `
			width: 100%;
			position: fixed;
			top: -${window.pageYOffset}px;
      overflow: hidden;
      padding-right: ${scrollWidth}px;
    `;
	}
}
