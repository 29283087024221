function dropdown() {
  //const detailsBlock = document.querySelector('details');

  //if (!detailsBlock) return;
  /*document.addEventListener('click', (e) => {
    const target = e.target;

    if (!target.closest('details summary')) return;

    const details = target.closest('details'),
    detailsContent = details.querySelector('div');

    if (!details.open) {
      detailsContent.style.maxHeight = `${detailsContent.scrollHeight}px`;
    } else {
      e.preventDefault();

      detailsContent.style.maxHeight = '0px';

      setTimeout(() => {
        details.open = false;
      }, 300)
    }
  })*/
}
